import { IForm } from '../models/form.interface';

export class CreateForm {
  static readonly type = '[UVA Team] Create Form';

  constructor(readonly payload: IForm) {}
}

export class CreateFormSuccess {
  static readonly type = '[UVA Team] Create Form Success';
}

export class CreateFormError {
  static readonly type = '[UVA Team] Create Form Error';
  constructor(readonly error: string) {}
}
