import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FormBuilderService } from '../services/form-builder.service';
import { CreateForm, CreateFormError, CreateFormSuccess } from './uva-team.actions';
import { catchError, of, tap } from 'rxjs';

export interface IUVATeamState {
  formBuilder: {
    isCreating: boolean;
    isCreatingError: boolean;
    isCreatingSuccess: boolean;
  };
}

@State<IUVATeamState>({
  name: 'uvaTeam',
  defaults: {
    formBuilder: {
      isCreating: false,
      isCreatingError: false,
      isCreatingSuccess: false,
    },
  },
})
@Injectable()
export class UVATeamState {
  formBuilderService = inject(FormBuilderService);

  /**
   * Create Form
   */
  @Action(CreateForm)
  async createForm(ctx: StateContext<IUVATeamState>, action: CreateForm) {
    const state = ctx.getState();

    ctx.setState({ ...state, formBuilder: { ...state.formBuilder, isCreating: true } });

    return this.formBuilderService.createForm(action.payload).pipe(
      tap(() => {
        ctx.dispatch(new CreateFormSuccess());
      }),
      catchError((error: any) => {
        ctx.dispatch(new CreateFormError(error.message));
        return of(error);
      }),
    );
  }

  @Action(CreateFormSuccess)
  createFormSuccess(ctx: StateContext<IUVATeamState>) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      formBuilder: {
        ...state.formBuilder,
        isCreating: false,
        isCreatingSuccess: true,
      },
    });
  }

  @Action(CreateFormError)
  createFormError(ctx: StateContext<IUVATeamState>) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      formBuilder: {
        ...state.formBuilder,
        isCreating: false,
        isCreatingError: true,
      },
    });
  }
}
